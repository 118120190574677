.appliance-list {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.room-section {
  margin-bottom: 30px;
}

.room-section h2 {
  color: #333;
  border-bottom: 2px solid #4682b4;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.appliance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.appliance-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.appliance-card.selected {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.appliance-card .quantity {
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}

.edit-controls {
  margin-top: 10px;
}

.edit-controls button {
  background-color: #4682b4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-controls button:hover {
  background-color: #3a6d99;
}

.edit-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-fields label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-fields input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-appliance-form {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-appliance-form h2 {
  margin-top: 0;
  text-align: center;
  color: #4682b4;
}

.add-appliance-form .form-group {
  margin-bottom: 15px;
}

.add-appliance-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.add-appliance-form .form-group input[type="text"],
.add-appliance-form .form-group input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.add-appliance-form .form-group input[type="checkbox"] {
  margin-right: 10px;
}

.add-appliance-form .explainer-text {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

.add-appliance-form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4682b4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-appliance-form button:hover {
  background-color: #3a6d99;
}

.floating-appliance-info {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-width: 300px;
  max-height: 70vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.floating-appliance-info:hover {
  transform: scale(1.02);
}

.floating-appliance-info h3 {
  margin: 0 0 15px 0;
  color: #4682b4;
  font-size: 1.3rem;
  border-bottom: 2px solid #4682b4;
  padding-bottom: 10px;
}

.floating-appliance-info .total-kwh {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
  text-align: center;
}

.floating-system-info {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-width: 250px;
  max-height: 70vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  margin: 0 auto 20px auto;
}

.floating-system-info:hover {
  transform: scale(1.02);
}

.floating-system-info h3 {
  margin: 0 0 15px 0;
  color: #4682b4;
  font-size: 1.3rem;
  border-bottom: 2px solid #4682b4;
  padding-bottom: 10px;
}

.floating-system-info .system-info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.floating-system-info .system-info-item span {
  font-weight: bold;
  font-size: 0.9em;
}

.floating-system-info .battery-backup-slider, .floating-system-info .solar-panel-toggle, .floating-system-info .solar-panel-input {
  margin-top: 10px;
}

.floating-system-info .solar-panel-input label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.floating-system-info .solar-panel-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.floating-system-info .battery-type {
  margin-top: 10px;
}

.floating-system-info .battery-type label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.floating-system-info .battery-type input {
  margin-right: 10px;
}

.system-report {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.system-report h2 {
  margin-top: 0;
  text-align: center;
}

.system-report .report-section {
  margin-bottom: 20px;
}

.system-report .report-section h3 {
  margin-bottom: 10px;
  text-align: center;
}

.system-report .report-section ul {
  list-style-type: none;
  padding-left: 0;
}

.system-report .report-section ul li {
  margin-bottom: 5px;
}

.report-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.report-actions button {
  background-color: #4682b4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-actions button:hover {
  background-color: #3a6d99;
}

.notification-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.reset-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #d32f2f;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .appliance-list {
    padding: 10px;
  }

  .room-section h2 {
    font-size: 1.2em;
  }

  .appliance-grid {
    display: flex;
    flex-direction: column;
  }

  .appliance-card {
    margin-bottom: 10px;
  }

  .add-appliance-form, .floating-appliance-info, .floating-system-info {
    width: 100%;
    margin: 0 auto;
  }

  .floating-appliance-info, .floating-system-info {
    display: block; /* Ensure they are displayed */
  }

  .floating-appliance-info .content, .floating-system-info .content {
    display: none; /* Hide the content by default */
  }

  .floating-appliance-info.collapsed .content, .floating-system-info.collapsed .content {
    display: block; /* Show the content when not collapsed */
  }

  .floating-appliance-info .title, .floating-system-info .title {
    cursor: pointer;
    background-color: #4682b4;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }

  .system-info-item, .solar-panel-input, .battery-backup-slider, .solar-panel-toggle {
    text-align: left;
  }

  .system-info-item span {
    display: block;
    margin-bottom: 5px;
  }

  .solar-panel-input input, .battery-backup-slider input {
    width: 100%;
  }
}

/* Tablet Styles */
@media (min-width: 601px) and (max-width: 1024px) {
  .appliance-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }

  .add-appliance-form, .floating-appliance-info, .floating-system-info {
    width: 80%;
    margin: 0 auto;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .appliance-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  .add-appliance-form, .floating-appliance-info, .floating-system-info {
    width: 60%;
    margin: 0 auto;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .floating-appliance-info.mobile,
  .floating-system-info.mobile {
    display: none;
  }
}