.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2 {
  color: #2c3e50;
}

.appliance-list, .total-consumption, .solar-system-suggestion {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 5px;
}

.appliance-item, .add-appliance {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

input {
  padding: 5px;
  border: 1px solid #bdc3c7;
  border-radius: 3px;
}

button {
  padding: 5px 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #2980b9;
}
.common-appliances-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.appliance-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.appliance-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.appliance-card.selected {
  background-color: #a8e6cf;
  border: 2px solid #1de9b6;
}

.appliance-card h3 {
  margin-top: 0;
  font-size: 1.2em;
  color: #333;
}

.appliance-card p {
  margin: 5px 0;
  color: #666;
}

.selected-appliances {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.selected-appliance-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.selected-appliance-item h4 {
  margin-top: 0;
  color: #333;
}

.selected-appliance-item label {
  display: block;
  margin: 10px 0;
}

.selected-appliance-item input {
  width: 60px;
  padding: 5px;
  margin-left: 10px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.floating-appliance-info {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f0f8ff;
  border: 2px solid #4682b4;
  border-radius: 15px;
  padding: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 250px;
  max-height: 60vh;
  overflow-y: auto;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.floating-appliance-info:hover {
  transform: scale(1.02);
}

.floating-appliance-info h3 {
  margin: 0 0 8px 0;
  color: #4682b4;
  font-size: 1rem;
  border-bottom: 1px solid #4682b4;
  padding-bottom: 4px;
}

.selected-appliance-info {
  margin-bottom: 4px;
  font-size: 0.8rem;
}

.system-size-info {
  margin-top: 8px;
  font-size: 0.8rem;
}

.system-size-info p {
  margin: 2px 0;
}

.site-header {
  background-color: #2c3e50;
  color: white;
  padding: 20px 0;
  text-align: center;
  margin-bottom: 20px;
}

.site-header h1 {
  margin: 0;
  font-size: 2.5em;
}

.intro-text {
  max-width: 800px;
  margin: 0 auto 30px;
  padding: 0 20px;
  text-align: center;
  line-height: 1.6;
  color: #333;
}

.intro-text p {
  margin-bottom: 15px;
}

.intro-text ul {
  text-align: left;
  max-width: 600px;
  margin: 20px auto;
  padding-left: 30px;
}

.intro-text li {
  margin-bottom: 10px;
}